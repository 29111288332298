import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"

import {
  Title,
  Subtitle,
  Container,
} from "bloomer"
import { useStaticQuery } from "gatsby"

export default function TeamMember(props) {
  return (
    <Container hasTextAlign="centered">
      <figure class="team-member image">
        <img className="is-rounded has-image-centered" src={props.imageUrl}></img>
      </figure>
      <Title isSize={5}>{props.name}</Title>
      <Subtitle isSize={6}>{props.title}</Subtitle>
    </Container>
  )
}

TeamMember.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  imageUrl: PropTypes.string,
}

