import React from "react"

import { Title, Section, Container } from "bloomer"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TeamMember from "../components/team-member"
import { Columns, Column, Box, Icon, Content, Subtitle } from "bloomer"
import Header from "../components/header"

import Timothy from "../images/people/TimothyDeakin_lab.png"
import Ashley from "../images/people/AshleyBrown_lab.png"
import Benjamin from "../images/people/BenRogers_lab.png"
import Kyle from "../images/people/KyleHawthorne_lab.png"

const AboutPage = () => (
  <Layout>
    <SEO title="About" />
    <Header title="About"></Header>
    <Section>
      <Container>
        

        <Columns>
          <Column is={1/2}>
          <Title>Our Story</Title>
            <Box>
              <p>
                Founded in March 2016 by Tim and Ashley, Netfence is a Cambridge
                based cyber-security consultant providing penetration testing,
                vulnerability scanning and custom security solutions.
              </p>
              <br />
              <p>
                We aim to be as Ethical and transparent as possible with our
                testing. Because we’re a smaller team we’re able to work
                directly with you to provide bespoke solutions.
              </p>
            </Box>
          </Column>
          <Column is={1/2}>
            <Content>
              <Title>Why Netfence?</Title>
              <Box>
                <ul id="list-unstyled">
                  <li>
                    <Icon
                      className="fas fa-check fa-lg has-text-primary"
                      id="feature-icon"
                    />
                    We only deliver high quality reports.
                  </li>
                  <li>
                    <Icon
                      className="fas fa-check fa-lg has-text-primary"
                      id="feature-icon"
                    />
                    We've streamlined the testing process.
                  </li>
                  <li>
                    <Icon
                      className="fas fa-check fa-lg has-text-primary"
                      id="feature-icon"
                    />
                    We can work directly with you to provide the best solution.
                  </li>
                </ul>
              </Box>
            </Content>
          </Column>
        </Columns>
      </Container>
    </Section>
    <Section>
      <Container>
        <Title>The Team</Title>
        <Columns>
          <Column isSize={1 / 4}>
            <TeamMember
              name="Timothy Deakin"
              title="Managing Director"
              imageUrl={Timothy}
            />
          </Column>
          <Column isSize={1 / 4}>
            <TeamMember
              name="Ashley Brown"
              title="Director of IT"
              imageUrl={Ashley}
            />
          </Column>
          <Column isSize={1 / 4}>
            <TeamMember
              name="Benjamin Rogers"
              title="Software Engineer"
              imageUrl={Benjamin}
            />
          </Column>
          {/* <Column isSize={1 / 4}>
            <TeamMember
              name="Kyle Hawthorne"
              title="Security Consultant"
              imageUrl={Kyle}
            />
          </Column> */}
        </Columns>
      </Container>
    </Section>
  </Layout>
)

export default AboutPage
